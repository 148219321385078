exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-hobbies-index-js": () => import("./../../../src/pages/hobbies/index.js" /* webpackChunkName: "component---src-pages-hobbies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog-tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */),
  "component---src-templates-blogs-all-js": () => import("./../../../src/templates/blogs-all.js" /* webpackChunkName: "component---src-templates-blogs-all-js" */)
}

